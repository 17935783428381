import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { app } from '../../base';
import logoImage from '../../styles/images/sqwad-hand.png';
import logoWithTextImageBlack from '../../styles/images/new_sqwad_logo.png';
import '../../styles/css/main.css';
import swal from 'sweetalert2';

class AdminLogin extends Component {
    constructor(props) {
        super(props);
        this.authWithEmailPassword = this.authWithEmailPassword.bind(this);
        this.state = {
            loading: true,
            redirect: false
        };
    }

    authWithEmailPassword(event) {
        event.preventDefault();
        const email = this.emailInput.value;
        const password = this.passwordInput.value;
        this.setState({ loading: true });
        app.auth().fetchSignInMethodsForEmail(email)
            .then((providers) => {
                this.setState({ loading: false });
                return app.auth().signInWithEmailAndPassword(email, password);
            })
            .then((user) => {
                if (user) {
                    this.loginForm.reset();
                    this.props.setCurrentUser(user);
                    this.setState({
                        redirect: true,
                    });
                } else {
                    swal.fire({
                        title: 'Wrong credentials',
                        text: "Check your email and password and try again!",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                swal.fire({
                    title: 'Uh oh!',
                    text: error.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            });
    }

    componentDidMount() {
        if (app.auth().currentUser) {
            this.setState({ redirect: true });
        }
        this.setState({
            loading: false
        });
    }

    render() {
        const teamLogo = logoImage;
        if (this.state.redirect || this.props.user) {
            return <Redirect to="/admin" />;
        }
        return (
            <div className="auth-fluid d-flex min-vh-100 h-100">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />

                <div className="auth-fluid-form-box col-md-6 d-flex flex-column align-items-center justify-content-center h-100">
                    <div className="text-center w-100">
                        <div className="auth-brand mb-5">
                            <img src={logoWithTextImageBlack} alt="Logo" width="200px" />
                        </div>
                        <h4 className="mb-3 fw-bold">Welcome</h4>
                        <p className="text-muted mb-4 fw-bold">Enter your email address and password to access your account.</p>
                        <form onSubmit={this.authWithEmailPassword} ref={(form) => { this.loginForm = form }}>
                            <div className="mb-3">
                                <label htmlFor="emailaddress" className="form-label">Email address</label>
                                <input className="form-control" type="email" name="email" ref={(input) => { this.emailInput = input }} placeholder="Enter your email" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input className="form-control" name="password" type="password" ref={(input) => { this.passwordInput = input }} placeholder="Enter your password" required />
                            </div>
                            <div className="d-grid">
                                <button className="btn btn-primary" type="submit" style={{ backgroundColor: "#ff1f3e", borderColor: "#ff1f3e" }}>Log In</button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="auth-fluid-right col-md-6 text-center d-flex align-items-center justify-content-center flex-column h-100">
                    <div className="auth-user-testimonial flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                        <h2 className="mb-3 fw-bold">Tip Of The Month</h2>
                        <p className="lead fw-bold">Plug the emails collected into Facebook, Instagram, & Snapchat ads for ticketing retargeting (5x-8x returns)</p>
                        <img src={teamLogo} width="200px" alt="Team Logo" />
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminLogin;
