import React from 'react';
import MyCrossword from 'mycrossword';
import 'mycrossword/dist/index.css';
import '../../styles/css/CrosswordCss.css';

const CrosswordComponent = ({ data, onSaveGrid, className, ...props }) => {
    return (
        <MyCrossword
            data={data}
            saveGrid={onSaveGrid}
            className={className}
            {...props}  // Pass down any additional props to MyCrossword
        />
    );
};

export default CrosswordComponent;
