import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';

const CrosswordUpload = ({ onCrosswordDataUpdate }) => {
    const [crosswordData, setCrosswordData] = useState([]);
    const [word, setWord] = useState("");
    const [clue, setClue] = useState("");
    const [editIndex, setEditIndex] = useState(null); // Track the index of the item being edited

    useEffect(() => {
        onCrosswordDataUpdate(crosswordData); // Pass up the crossword data whenever it changes
    }, [crosswordData]);

    // Handle CSV file upload and parsing
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                header: true,
                complete: (result) => {
                    const parsedData = result.data
                        .map((item) => ({
                            word: item.Word || item.word || "",  // Default to empty string if no word
                            clue: item.Clue || item.clue || ""   // Default to empty string if no clue
                        }))
                        .filter(item => item.word && item.clue); // Filter out incomplete rows
                    setCrosswordData((prevData) => [...prevData, ...parsedData]);  // Add all parsed data to existing data
                },
                error: (error) => {
                    console.error("Error parsing CSV:", error);
                }
            });
        }
    };

    // Handle manual entry of word and clue
    const handleAddEntry = () => {
        if (word && clue) {
            if (editIndex !== null) {
                // Update the existing entry
                const updatedData = crosswordData.map((item, index) =>
                    index === editIndex ? { word, clue } : item
                );
                setCrosswordData(updatedData);
                setEditIndex(null); // Reset edit index
            } else {
                // Add a new entry
                setCrosswordData([...crosswordData, { word, clue }]);
            }
            setWord("");  // Clear input fields
            setClue("");
        } else {
            alert("Please enter both a word and a clue.");
        }
    };

    // Handle edit button click
    const handleEdit = (index) => {
        const item = crosswordData[index];
        setWord(item.word);
        setClue(item.clue);
        setEditIndex(index); // Set edit index
    };

    // Handle delete button click
    const handleDelete = (index) => {
        const updatedData = crosswordData.filter((_, i) => i !== index);
        setCrosswordData(updatedData);
        if (editIndex === index) {
            // Clear inputs if the currently edited item is deleted
            setWord("");
            setClue("");
            setEditIndex(null);
        }
    };

    return (
        <div className="tab-pane" id="second">
            <div className="row">
                <div className="col-12">
                    <div className="form-group" align="center">
                        <h4 style={{ width: '100%' }}>Create Crossword Table</h4>
                    </div>
                </div>
            </div>
            {/* CSV Upload Section */}
            <div className="row mt-3">
                <div className="col-6" align="center">
                    <input
                        type="file"
                        accept=".csv"
                        onChange={handleFileUpload}
                    />
                    <p className="mt-2">Upload CSV with columns "word" and "clue"</p>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Enter word"
                            value={word}
                            onChange={(e) => setWord(e.target.value)}
                            className="form-control mb-2"
                        />
                        <input
                            type="text"
                            placeholder="Enter clue"
                            value={clue}
                            onChange={(e) => setClue(e.target.value)}
                            className="form-control mb-2"
                        />
                        <button onClick={handleAddEntry} className="btn btn-primary">
                            {editIndex !== null ? "Update Word and Clue" : "Add Word and Clue"}
                        </button>
                    </div>
                </div>
            </div>
            {/* Display Crossword Data */}
            {crosswordData.length > 0 && (
                <div className="row mt-3">
                    <div className="col-12">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>Word</th>
                                <th>Clue</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {crosswordData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.word}</td>
                                    <td>{item.clue}</td>
                                    <td>
                                        <button
                                            onClick={() => handleEdit(index)}
                                            className="btn btn-sm btn-warning mr-2"
                                        >
                                            Edit
                                        </button>
                                        <button
                                            onClick={() => handleDelete(index)}
                                            className="btn btn-sm btn-danger"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CrosswordUpload;
